import { createApi } from '@reduxjs/toolkit/query/react';
import { createSelector } from '@reduxjs/toolkit';
import { rapidstorBaseQuery } from './generateRapidstorQuery';
import Location, { locationTransformer } from '../entities/Location';
import { RapidStorLocation, RapidStorResponse } from '../services/rapidstor';
import { RootState } from './index';
import { isTelemetryEnabled } from '../config';

export const HIDDEN_LOCATIONS = isTelemetryEnabled() ? ['LSETUP'] : [];

export const locationApi = createApi({
	reducerPath: 'locationApi',
	baseQuery: rapidstorBaseQuery,
	keepUnusedDataFor: 3600,
	endpoints: (build) => ({
		getLocations: build.query<Location[], void>({
			query: () => 'locations',
			transformResponse: (resp: RapidStorResponse<RapidStorLocation[]>) => {
				return resp.results
					.map((l) => locationTransformer(l))
					.filter((l) => !HIDDEN_LOCATIONS.includes(l.locationCode) && l.rapidstorEnabled);
			},
		}),
		getLocation: build.query<Location, string>({
			query: (locationCode) => `locations/${locationCode}`,
			transformResponse: (resp: RapidStorResponse<RapidStorLocation>) =>
				locationTransformer(resp.results),
			extraOptions: {
				retry: 3,
			},
		}),
	}),
});

export const useGetLocationsQuery = locationApi.endpoints.getLocations.useQuery;
export const useGetLocationQuery = locationApi.endpoints.getLocation.useQuery;

export default locationApi.reducer;

const selectLocationsResult = locationApi.endpoints.getLocations.select();

export const selectLocations = createSelector(
	selectLocationsResult,
	(result) => result?.data ?? []
);

export const selectLocation = createSelector(
	selectLocations,
	(state: RootState, locationCode: string) => locationCode,
	(locations, locationCode) =>
		locations.find((l) => l.locationCode === locationCode) ?? null
);

export const selectSite = createSelector(
	selectLocations,
	(result: RootState, locationCode: string) => locationCode,
	(locations, locationCode) =>
		locations.find((l) => l.locationCode === locationCode)
);
