import { RapidStorInsurance } from '../services/rapidstor';

export interface Insurance {
  hidden: boolean;
  deleted: boolean;
  maxArea: string | null;
  helpfulText: string;
  _id: string;
  insuranceCoverageId: number;
  locationCode: string;
  siteId: number;
  changeToken: string;
  changedAt: string;
  coverage: number;
  premium: number;
  weeklyPremium: number;
  coverageDescription: string;
  providor: string;
}

export const transformInsurance = (
	insurance: RapidStorInsurance
): Insurance => ({
	hidden: insurance.hidden,
	deleted: insurance.deleted,
	maxArea: insurance.maxArea,
	helpfulText: insurance.helpfulText,
	_id: insurance._id,
	insuranceCoverageId: insurance.InsurCoverageID,
	locationCode: insurance.sLocationCode,
	siteId: insurance.SiteID,
	changeToken: insurance.changeToken,
	changedAt: insurance.changedAt,
	coverage: insurance.dcCoverage,
	premium: insurance.dcPremium,
	weeklyPremium: insurance.dcPremium / 4,
	coverageDescription: insurance.sCoverageDesc,
	providor: insurance.sProvidor,
});
