import { RapidStorDescriptor } from '../services/rapidstor';
import UnitType, { unitTypeTransformer } from './UnitType';

export default interface Descriptor {
  _id: string;
  locationCode: string;
  corpCode: string;
  name: string;
  description: string;
  picture: string;
  defaultInsuranceCoverage: string;
  deals: string[];
  enabled: boolean;
  hidden: boolean;
  unitTypes: UnitType[];
}

export const descriptorTransformer = (
	descriptor: RapidStorDescriptor
): Descriptor => {
	return {
		_id: descriptor._id,
		locationCode: descriptor.sLocationCode,
		corpCode: descriptor.sCorpCode,
		name: descriptor.name,
		description: descriptor.description,
		picture: descriptor.picture,
		defaultInsuranceCoverage: descriptor.defaultInsuranceCoverage,
		deals: descriptor.deals,
		enabled: descriptor.enabled,
		hidden: descriptor.hidden,
		unitTypes: descriptor.unitTypes.map((u) => unitTypeTransformer(u)),
	};
};
