import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleApiWrapper, GoogleAPI, IMapProps } from 'google-maps-react';
import Map, { MapTest } from './Map';
import Location from '../../entities/Location';

type LatLngLiteral = google.maps.LatLngLiteral;

export interface MapLocation extends Location {
  distance?: number;
}

interface MapProps {
  google: GoogleAPI;
  initialCenter?: LatLngLiteral;
  initialZoom?: number;
  searchRef?: React.RefObject<HTMLInputElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  placeChanged: (place: google.maps.places.PlaceResult, maps: any) => void;
  locations: MapLocation[];

  activeLocation: MapLocation | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onActiveLocationChange: (location: MapLocation | null, maps: any) => void;
  onReady?: () => void;
}

const LocationMap: React.FC<MapProps> = ({
	google,
	initialCenter,
	initialZoom,
	locations,
	searchRef,
	placeChanged,

	activeLocation,
	onActiveLocationChange,
	onReady,
}) => {
	const mapRef = useRef<MapTest>(null);
	const [markers, setMarkers] = useState<
    { marker: google.maps.Marker; tooltip: google.maps.InfoWindow }[]
  >([]);
	const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>();
	const [mapLoading, setMapLoading] = useState(true);

	useEffect(() => {
		if (locations.length === 0) {
			return;
		}

		if (!mapRef.current?.test?.map) {
			return;
		}

		if (markers.length === 0) {
			setMarkers(
				locations.map((location) => {
					const marker = new google.maps.Marker({
						position: { lat: location.latitude, lng: location.longitude },
						title: location.name,
						map: mapRef.current?.test?.map,
						icon: {
							url: '/images/access-map-pin.svg',
							scaledSize: new google.maps.Size(38, 50),
						},
					});
					const tooltip = new google.maps.InfoWindow({
						content: `<h5 style="margin: 0;">${location.name}</h5>`,
						disableAutoPan: true,
					});

					marker.addListener('click', () => {
						onActiveLocationChange(location, google.maps);
					});
					return { marker, tooltip };
				})
			);
		} else {
			locations.map((location) => {
				const marker = markers.find(
					(marker) => marker.marker.getTitle() == location.name
				);

				if (!marker) {
					return;
				}

				const icon =
          !activeLocation ||
          location.locationCode === activeLocation.locationCode
          	? '/images/access-map-pin.svg'
          	: '/images/access-map-pin-inactive.svg';

				marker.marker.setIcon({
					url: icon,
					scaledSize: new google.maps.Size(38, 50),
				});
			});
		}
	}, [
		locations,
		activeLocation,
		markers,
		google.maps,
		onActiveLocationChange,
		mapLoading,
	]);

	useEffect(() => {
		if (
			!mapRef.current ||
      !mapRef.current.camera ||
      locations.length === 0 ||
      markers.length === 0
		) {
			return;
		}

		const bounds = new google.maps.LatLngBounds();

		markers.forEach((marker) => marker.tooltip.close());

		if (activeLocation) {
			bounds.extend({
				lat: activeLocation.latitude,
				lng: activeLocation.longitude,
			});
			const marker = markers.find(
				(m) => m.marker.getTitle() === activeLocation.name
			);

      marker!.tooltip.open({
      	map: mapRef.current!.test!.map,
      	anchor: marker!.marker,
      	shouldFocus: false,
      });
		} else {
			locations.forEach((location) => {
				bounds.extend({ lat: location.latitude, lng: location.longitude });
			});
		}

		mapRef.current.camera.animateToBounds(bounds);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeLocation]);

	const handlePlaceChanged = useCallback(() => {
		if (autocomplete) {
			placeChanged(autocomplete.getPlace(), google.maps);
		}
	}, [autocomplete, google.maps, placeChanged]);

	useEffect(() => {
		if (!searchRef || !searchRef.current || autocomplete) {
			return;
		}

		const _addEventListener = searchRef.current.addEventListener;

		// If enter is placed before any autocompletes are selected, simulate selecting the first one
		searchRef.current.addEventListener = (
			type: string,
			listener: EventListener
		) => {
			if (type == 'keydown') {
				const originalListener = listener;

				listener = function (event: Event) {
					const suggested =
            document.querySelectorAll('.pac-item-selected').length > 0;

					if ((event as KeyboardEvent).key === 'Enter' && !suggested) {
						const simulatedSelect = new KeyboardEvent('keypress', {
							keyCode: 40,
						});

						originalListener.apply(searchRef.current, [simulatedSelect]);
					}
					originalListener.apply(searchRef.current, [event]);
				};
			}

			_addEventListener.apply(searchRef.current, [type, listener]);
		};

		const ac = new google.maps.places.Autocomplete(searchRef.current, {
			types: ['(regions)'],
			componentRestrictions: { country: 'gb' },
		});

		setAutocomplete(ac);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (autocomplete) {
			const handle = autocomplete.addListener(
				'place_changed',
				handlePlaceChanged
			);

			return () => {
				google.maps.event.removeListener(handle);
			};
		}
	}, [autocomplete, google.maps.event, handlePlaceChanged]);

	useEffect(() => {
		if (!mapRef.current || !mapRef.current.camera) {
			return;
		}

		if (!mapLoading && markers.length !== 0) {
			const bounds = new google.maps.LatLngBounds();

			if (activeLocation) {
				bounds.extend({
					lat: activeLocation.latitude,
					lng: activeLocation.longitude,
				});
			} else {
				locations.forEach((location) => {
					bounds.extend({ lat: location.latitude, lng: location.longitude });
				});
			}

			mapRef.current.camera.animateToBounds(bounds);
		}
	}, [
		activeLocation,
		google.maps.LatLngBounds,
		locations,
		mapLoading,
		markers,
	]);

	const onInnerMapReady: IMapProps['onReady'] = () => {
		setTimeout(() => {
			setMapLoading(false);

			onReady && onReady();
		}, 1000);
	};

	return (
		<div>
			<Map
				ref={mapRef}
				google={google}
				initialCenter={initialCenter}
				initialZoom={initialZoom || 5}
				onReady={onInnerMapReady}
			/>
		</div>
	);
};

export default GoogleApiWrapper({
	apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
	// version: 'beta',
	libraries: ['places', 'geometry'],
})(LocationMap);
