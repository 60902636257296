/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebookSquare,
	faTwitterSquare,
	faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons';
import styles from '../../styles/Footer.module.scss';

const Footer: React.FC = () => (
	<div
		className={`${styles.footer} d-none d-md-flex w-100 align-items-center`}
		style={{ marginTop: 'auto' }}
	>
		<div className={`${styles['footer-nav']} ${styles['footer-column']}`}>
			<span style={{ marginRight: '0.75rem' }}>
				<a
					href="https://www.facebook.com/Cinchstorage/"
					target="_blank"
					rel="noopener"
				>
					<FontAwesomeIcon
						icon={faFacebookSquare}
						style={{ fontSize: '1.5rem' }}
					/>
				</a>
			</span>
			<span style={{ marginRight: '0.75rem' }}>
				<a
					href="https://twitter.com/cinchstorage"
					target="_blank"
					rel="noopener"
				>
					<FontAwesomeIcon
						icon={faTwitterSquare}
						style={{ fontSize: '1.5rem' }}
					/>
				</a>
			</span>
			<span style={{ marginRight: '0.75rem' }}>
				<a
					href="https://www.instagram.com/cinchstorage/"
					target="_blank"
					rel="noopener"
				>
					<FontAwesomeIcon
						icon={faInstagramSquare}
						style={{ fontSize: '1.5rem' }}
					/>
				</a>
			</span>
		</div>
		<div className={`${styles['footer-column']} text-center`}>
			<span>&copy; {new Date().getFullYear()} All rights reserved</span>
		</div>
		<div
			className={`${styles['footer-nav']} ${styles['footer-column']} text-end`}
		>
			<a
				href={`${process.env.siteUrl}/contact-us`}
				target="_blank"
				rel="noopener"
			>
        Contact Us
			</a>
			<a
				href={`${process.env.siteUrl}/privacy-policy`}
				target="_blank"
				rel="noopener"
			>
        Privacy Policy
			</a>
			<a href={`${process.env.siteUrl}/blog`} target="_blank" rel="noopener">
        News
			</a>
		</div>
	</div>
);

export default Footer;
