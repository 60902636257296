import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from 'react-ga4';
import store from '../store';
import '../styles/globals.scss';
import Head from 'next/head';

const gaMeasurementId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID;

if (gaMeasurementId) {
	ReactGA.initialize(gaMeasurementId);
}

const persistor = persistStore(store);

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<>
			<Head>
				<title>Cinch Self Storage</title>
			</Head>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Component {...pageProps} />
				</PersistGate>
			</Provider>
		</>
	);
}

export default MyApp;
