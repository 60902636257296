import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';
import { Notification as notification, removeNotification } from '../../../store/notifySlice';
import { useAppDispatch } from '../../../hooks';

const Notification: React.FC<notification> = ({
	id,
	type,
	message,
	duration
}) => {
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(true);

	const handleClose = () => {
		setOpen(false);
		setTimeout(() => {
			dispatch(removeNotification(id));
		}, 500);
	};

	return (
		<Toast
			show={open}
			delay={duration}
			autohide
			animation
			onClose={handleClose}
			bg={type}
		>
			<Toast.Body className="text-light">{message}</Toast.Body>
		</Toast>
	);
};

export default Notification;
