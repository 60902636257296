export const isProduction = (): boolean =>
	process.env.NODE_ENV === 'production';

export const isDevelopment = (): boolean =>
	process.env.NODE_ENV === 'development';

// Telemetry flag used for enabling Google Analytics/Tag Manager and Show Prices Email/Event on production
export const isTelemetryEnabled = (): boolean =>
	process.env.NEXT_PUBLIC_TELEMETRY_ENABLED === 'true';

export const isDesignTestEnabled = (): boolean => {
	return process.env.pricePageV2 === 'true';
}

export const getReservationFee = (opt_location?: string): number => {
	const feeOverrides: {[location: string]: number} = {
		'L002': 10,
		'L003': 10,
		'L006': 10,
		'L007': 10,
	};
	if (opt_location && opt_location in feeOverrides) {
		return feeOverrides[opt_location];
	}

	return process.env.reservationFee ? parseFloat(process.env.reservationFee) : 20;
}
