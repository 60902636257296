import { useAppDispatch } from './index';
import { Notification, pushNotification } from '../store/notifySlice';

const useNotify = () => {
	const dispatch = useAppDispatch();

	const notify = ({
		type = 'default',
		message = '',
		duration = 5000,
		canClose = true,
	}: Omit<Notification, 'id'>) => {
		dispatch(pushNotification({ type, message, duration, canClose }));
	};

	return {
		notify,
	};
};

export default useNotify;
