import React from 'react';
import { useAppSelector } from '../../hooks';
import { selectQuoteSteps } from '../../store/quoteSlice';
import QuoteStep, { QuoteStepProps } from './QuoteStep';
import { useRouter } from 'next/router';

interface QuoteStepsProps {
    className: string;
    isPaySteps?: boolean;
}

const QuoteSteps: React.FC<QuoteStepsProps> = ({ className, isPaySteps }) => {
	const quoteSteps = useAppSelector(selectQuoteSteps);
	const router = useRouter();

	const paySteps: QuoteStepProps[] = [
		{
			index: 1,
			name: 'Pay Deposit',
			href: '/reservation/pay',
			active: router.route === '/reservation/pay',
			valid: router.route === '/reservation/success'
		},
		{
			index: 2,
			name: 'Reserved',
			href: '/reservation/success',
			active: router.route === '/reservation/success',
			valid: false
		}
	];

	return (
		<div className={`d-flex flex-row justify-content-center ${className} gap-5`}>
			{isPaySteps ? (
				paySteps.map((step) => (
					<QuoteStep
						key={step.index}
						index={step.index}
						name={step.name}
						href={step.href}
						active={step.active}
						valid={step.valid}
					/>
				))
			) : (
				quoteSteps.map((step) => (
					<QuoteStep
						key={step.name}
						index={step.id}
						name={step.name}
						href={step.url}
						active={step.active}
						valid={step.valid}
					/>
				))
			)}
		</div>
	);
};

export default QuoteSteps;
