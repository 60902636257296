import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  tokens: {
    rapidstor: null | string;
  };
}

const initialState: AuthState = {
	tokens: {
		rapidstor: null,
	},
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setRapidstorToken: (state, action: PayloadAction<string | null>) => {
			state.tokens.rapidstor = action.payload;
		},
	},
});

export const { setRapidstorToken } = authSlice.actions;

export default authSlice.reducer;
