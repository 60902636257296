var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5bTNimiCy3QnphUicIz0g"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { ExtraErrorData } from '@sentry/integrations';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	dsn:
    SENTRY_DSN ||
    'https://316839cb68d447a0a8bd1853f98e7a2b@o1132036.ingest.sentry.io/4504042782261248',
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 0.05,
	enabled: process.env.NODE_ENV === 'production',
	normalizeDepth: 10,
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
	integrations: [
		new ExtraErrorData({
			depth: 6,
		}),
	],
});
