import { createApi } from '@reduxjs/toolkit/query/react';
import { rapidstorBaseQuery } from '../store/generateRapidstorQuery';
import { RapidStorResponse, RapidStorReservationRequest } from './rapidstor';

interface MakeReservation {
  locationCode: string;
  data: RapidStorReservationRequest;
}

export type RapidStoreResponse<T = void> = {
  operation_message: string;
  operation_result: string;
  results: T;
}

export interface ReservationResponse {
  _id: string;
  waitingId: string;
  globalWaitingNum: string;
  tenantId: string;
  token: string;
}

type MakeReservationResponse = RapidStorResponse<{ redirect: string }>;

export const reservationApi = createApi({
	reducerPath: 'reservationApi',
	baseQuery: rapidstorBaseQuery,
	endpoints: (build) => ({
		makeReservation: build.mutation<MakeReservationResponse, MakeReservation>({
			query: ({ locationCode, data }) => ({
				url: `${locationCode}/sca/prepare/reservation-fee`,
				method: 'POST',
				body: data,
			}),
		}),
		/**
     * Below queries are for the RS reservation/lead replacement system
     */
		postReservation: build.mutation({
			query: ({ locationCode, payload }) => ({
				url: `/reservation`,
				method: "POST",
				body: payload,
				headers: {
					site: locationCode,
				},
			}),
			transformResponse: (resp: RapidStorResponse<ReservationResponse>) => {
				return resp.results;
			},
		}),
		patchReservation: build.mutation({
			query: ({ locationCode, payload, token }) => ({
				url: `/reservations/${token}`,
				method: "PATCH",
				body: payload,
				headers: {
					site: locationCode,
				}
			}),
			transformResponse: (resp: RapidStorResponse<ReservationResponse>) => {
				return resp.results;
			},
		}),
		postReservationFee: build.mutation({
			query: ({ locationCode, payload, token }) => ({
				url: `/api/v1/reservation/${token}/add-fee`,
				method: 'POST',
				body: payload,
				headers: {
					site: locationCode,
				},
			}),
			transformResponse: (resp: RapidStorResponse<ReservationResponse>) => {
				return resp.results;
			},
		}),
	}),
});

export const useMakeReservationMutation =
  reservationApi.endpoints.makeReservation.useMutation;
export const usePostReservationMutation =
  reservationApi.endpoints.postReservation.useMutation;
export const usePatchReservationMutation =
  reservationApi.endpoints.patchReservation.useMutation;
export const usePostReservationFeeMutation =
    reservationApi.endpoints.postReservationFee.useMutation;

export default reservationApi.reducer;
