import { RapidStorRates, RapidStorUnitType } from '../services/rapidstor';

export default interface UnitType {
  corpCode: string;
  locationCode: string;
  unitTypeId: number;
  typeName: string;
  deleted: boolean;
  stdRate: number;
  stdWeeklyRate: number;
  area: number;
  width: number;
  length: number;
  floor: number;
  totalOccupied: number;
  totalReserved: number;
  totalUnits: number;
  totalVacant: number;
  firstAvailableUnitName: string;
  representativeUnitName: string;
  hasAvailableUnits: boolean;
  hasComputedAvailable: boolean;
  _id: string;
  deal?: Deal;
  rates: Rates;
}

export interface Deal {
  _id: string;
  title: string;
  descriptions: string[];
  period?: string;
  discountPlan?: {
    _id: string;
    concessionId: number;
  };
}

export interface Rates {
  discounted: boolean;
  discountedRate: number;
  rate: number;
  taxInclusive: boolean;
  tax1: number;
  tax2: number;
}

export const ratesTransformer = (rates: RapidStorRates): Rates => {
	return <Rates>{
		discounted: rates.discounted,
		// Always set discountedRate, even if there's no discount.
		// It makes price comparison between units much simpler.
		discountedRate: rates.discounted ? rates.discountedRate : rates.rate,
		rate: rates.rate,
		taxInclusive: rates.taxInclusive,
		tax1: rates.tax1,
		tax2: rates.tax2,
	};
};

export const unitTypeTransformer = (unitType: RapidStorUnitType): UnitType => {
	let deal: Deal | undefined = undefined;
	let rates: Rates;

	if (unitType.deals.length > 0) {
		const [firstDeal] = unitType.deals;

		let length;
		if (firstDeal.discountPlan) {
			let units = firstDeal.discountPlan.iExpirMonths;
			if (units > 0) {
				let unit = firstDeal.customFormat.showRatesAsWeekly ? 'week' : 'month';
				if (unit === 'week') {
					units = units * 4;
				}
				if (units > 1) {
					unit = unit + 's';
				}
				length = units + ' ' + unit;
			}
		}

		deal = {
			_id: firstDeal._id,
			title: firstDeal.title,
			descriptions: firstDeal.descriptions,
			period: length,
		};

		if (firstDeal.discountPlan) {
			deal.discountPlan = {
				_id: firstDeal.discountPlan._id,
				concessionId: firstDeal.discountPlan.ConcessionID,
			};
		}

		rates = ratesTransformer(unitType.deals[0].rates);
	} else {
		rates = ratesTransformer(unitType.rates);
	}

	return <UnitType>{
		corpCode: unitType.sCorpCode,
		locationCode: unitType.sLocationCode,
		unitTypeId: unitType.UnitTypeID,
		typeName: unitType.sTypeName,
		deleted: unitType.deleted,
		stdRate: unitType.dcStdRate,
		stdWeeklyRate: unitType.dcStdWeeklyRate,
		area: unitType.dcArea,
		width: unitType.dcWidth,
		length: unitType.dcLength,
		floor: unitType.iFloor,
		totalOccupied: unitType.iTotalOccupied,
		totalReserved: unitType.iTotalReserved,
		totalUnits: unitType.iTotalUnits,
		totalVacant: unitType.iTotalVacant,
		firstAvailableUnitName: unitType.sUnitName_FirstAvailable,
		representativeUnitName: unitType.sUnitName_Representative,
		hasAvailableUnits: unitType.hasAvailableUnits,
		hasComputedAvailable: unitType.hasComputedAvailable,
		_id: unitType._id,
		deal,
		rates,
	};
};
